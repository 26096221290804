// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-entradas-js": () => import("./../src/pages/catering/entradas.js" /* webpackChunkName: "component---src-pages-catering-entradas-js" */),
  "component---src-pages-catering-index-js": () => import("./../src/pages/catering/index.js" /* webpackChunkName: "component---src-pages-catering-index-js" */),
  "component---src-pages-catering-plato-fuerte-js": () => import("./../src/pages/catering/plato-fuerte.js" /* webpackChunkName: "component---src-pages-catering-plato-fuerte-js" */),
  "component---src-pages-catering-postre-js": () => import("./../src/pages/catering/postre.js" /* webpackChunkName: "component---src-pages-catering-postre-js" */),
  "component---src-pages-catering-primer-curso-js": () => import("./../src/pages/catering/primer-curso.js" /* webpackChunkName: "component---src-pages-catering-primer-curso-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-eventos-js": () => import("./../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-gracias-js": () => import("./../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-noticias-index-js": () => import("./../src/pages/noticias/index.js" /* webpackChunkName: "component---src-pages-noticias-index-js" */),
  "component---src-pages-plaza-cataluna-js": () => import("./../src/pages/plaza-cataluna.js" /* webpackChunkName: "component---src-pages-plaza-cataluna-js" */),
  "component---src-pages-proximos-eventos-js": () => import("./../src/pages/proximos-eventos.js" /* webpackChunkName: "component---src-pages-proximos-eventos-js" */),
  "component---src-pages-saint-jordi-js": () => import("./../src/pages/saint-jordi.js" /* webpackChunkName: "component---src-pages-saint-jordi-js" */),
  "component---src-templates-blog-post-contentful-js": () => import("./../src/templates/blog-post-contentful.js" /* webpackChunkName: "component---src-templates-blog-post-contentful-js" */)
}

